:root {
	font-size: 16px;
}
body {
  font-family: 'Source Sans Pro', sans-serif;
  color: white;
  height: 100vh;
}

h1 {
  font-size: 2rem;
  font-weight: 700;
}


.container {
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  background-color: transparent;
  padding: 0rem 1rem 1rem 1rem;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
}

.container ul {
  list-style: none;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
	flex-wrap: wrap;

  padding: 0rem;
  margin: 0rem;
}

.option {
  box-sizing: border-box;
  
  width: 10rem;
  height: 10rem;
  margin: 2rem;
  font-size: 3rem;
  font-weight: 700;
  
  border: 0.6rem solid white;
  border-radius: 3rem;
  color: white;
  background-color: transparent;
  
  transition: transform 0.5s;
}

.option:hover {
  transform: scale(1.2);
  transition: transform 0.5s;
}

#counter {
  align-self: flex-end;
  font-weight: 300;
  font-size: 3rem;
}

@media only screen and (max-width: 800px) {
	:root {
		font-size: 12px;
  }
}
